import Footer from './Footer';
import './Login.scss';
import Trailer from '../../../video/client-hub/trailer.mp4';
import Girl from '../../../images/client-hub/toka-girl.png';
import Arrow from '../../../images/client-hub/login-arrow.png';
import { useEffect, useState } from 'react';
import { clientHubLogin } from '../../../services/clientHub';

interface ILogin {
  setAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function Login({ setAuthorized }: ILogin) {
  const [check, setCheck] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Check if there is a saved token in session storage
    const token = sessionStorage.getItem('access_token');
    if (token) {
      setAuthorized(true); // Set authorized state to true if a token is found
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      const res = await clientHubLogin(email, password);
      if (res?.access_token) {
        // Save the token to session storage
        sessionStorage.setItem('access_token', res.access_token);
        setAuthorized(true); // Update state to reflect authorized status
      } else {
        setAuthorized(false); // Update state to reflect unauthorized status
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <div className='client-hub-login'>
      <div className='video-background'>
        <video className='video' preload='auto' loop playsInline autoPlay muted>
          <source src={Trailer} type='video/mp4' />
        </video>
      </div>

      <div className='login-form'>
        <form onSubmit={handleSubmit}>
          <div className='col left'>
            <div className='title'>
              <h1>Client Area</h1>
            </div>
            <div className='row'>
              <input
                type='email'
                className='client-hub-input'
                name='username'
                id='client-hub-username'
                placeholder='Username or Email Address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='row'>
              <input
                type='password'
                className='client-hub-input'
                name='password'
                id='client-hub-password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className='row'>
              <div className='checkbox'>
                <input
                  type='checkbox'
                  name='remember-me'
                  id='remember-me'
                  onClick={() => setCheck(!check)}
                  className={`${check ? 'checked' : ''}`}
                />
                <label htmlFor='remember-me'>Remember me</label>
              </div>
              <div className='button'>
                <button type='submit'>
                  <span>Log in</span> <img src={Arrow} alt='' />
                </button>
              </div>
            </div>
          </div>
          <div className='col right'>
            <img src={Girl} alt='' />
          </div>
        </form>
      </div>
      <div className='login-footer'>
        <Footer />
      </div>
    </div>
  );
}
