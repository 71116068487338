import { useEffect, useState } from 'react';
import Download from '../../../../images/client-hub/download_black.svg';
import DownloadRed from '../../../../images/client-hub/download_red.svg';

interface IDownload {
  name: string;
  activeFilters: string[];
  type: string;
  file: string;
}

export default function DownloadBox({ name, activeFilters, type, file }: IDownload) {
  const [onHover, setOnHover] = useState(false);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (activeFilters.includes(type) || activeFilters.includes('All')) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activeFilters, type]);
  return (
    <>
      {isActive && (
        <a
          className='download-box'
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          href={file !== "" ? file : "#"}
          download={file !== ""}
        >
          <div className='title'>
            <p>{name}</p>
          </div>
          <div className='icon'>
            <img src={onHover ? DownloadRed : Download} alt='' />
          </div>
        </a>
      )}
    </>
  );
}
