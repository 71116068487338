import { useState, useEffect, useContext } from 'react';

import 'react-step-progress-bar/styles.css';
// import { ProgressBar as PBar, Step } from 'react-step-progress-bar';
import './ProgressBar.scss';

interface IProgressBar {
  loginSteps: number;
  previousLoginSteps: number;
  isDesktop: boolean;
}

function ProgressBar({ loginSteps, isDesktop, previousLoginSteps }: IProgressBar) {
  const currentStep = showCurrentSteps();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const steps = isDesktop
    ? ['Connect Wallet', 'Sign Message', 'Create profile', 'Complete KYC', 'Start playing']
    : ['Connect Wallet', 'Create profile', 'Complete KYC', 'Start playing'];
  const percents = currentStep == 1 ? 0 : Math.round((currentStep - 1) * (100 / (steps.length - 1)));

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function showCurrentSteps(): number {
    if (isDesktop) {
      switch (loginSteps == 9 ? previousLoginSteps : loginSteps) {
        case 1:
          return 1;
        case 2:
          return 1;
        case 3:
          return 2;
        case 4:
          return 3;
        case 5:
          return 4;
        case 6:
          return 5;
        default:
          return 5;
      }
    } else {
      switch (loginSteps == 9 ? previousLoginSteps : loginSteps) {
        case 1:
          return 1;
        case 2:
          return 1;
        case 3:
          return 1;
        case 4:
          return 2;
        case 5:
          return 3;
        case 6:
          return 4;
        default:
          return 4;
      }
    }
  }

  return (
    <div
      style={{
        width: isDesktop ? '50%' : '80%',
        // display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: isDesktop ? '3rem' : `${(loginSteps == 9 || loginSteps == 6) && '3rem'}`,
        height: isDesktop ? 'auto' : '100px'
      }}
    >
      {/* <PBar percent={percents} filledBackground='#57bc18' height={2}>
        {steps.map((el: any, index: number) => (
          <Step key={index}>
            {({ accomplished, index }: any) => (
              <div
                className={`indexedStep ${accomplished ? 'accomplished' : null} ${
                  currentStep == index + 1 ? 'current' : null
                }
                `}
              >
                {' '}
                <span
                  style={{
                    position: 'absolute',
                    top: '2rem',
                    textAlign: 'center'
                  }}
                >
                  {el}
                </span>
              </div>
            )}
          </Step>
        ))}
      </PBar> */}
    </div>
  );
}

export default ProgressBar;
