import './SingleGame.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, Pagination, Parallax } from 'swiper';
import DownloadBox from './components/DownloadBox';
SwiperCore.use([Navigation, Pagination, Parallax]);

interface Asset {
  name: string;
  link: string;
}
interface IGame {
  name: string;
  headerImage: string;
  gallery: any;
  assets: any[];
  about: string;
  video: string;
}

export default function SingleGame({ name, headerImage, gallery, assets, about, video }: IGame) {
  return (
    <div className='client-hub-single-game'>
      <div className='game-header'>
        <div className='image'>
          <img src={headerImage} alt='' />
        </div>
        <div className='title'>
          <h1>{name}</h1>
        </div>
      </div>
      <div className='game-assets'>
        <div className='gallery'>
          <Swiper
            style={
              {
                '--swiper-pagination-color': '#1B1B1B',
                '--swiper-pagination-bullet-inactive-color': '#1B1B1B',
                '--swiper-pagination-bullet-inactive-opacity': '1'
              } as React.CSSProperties
            }
            pagination={{ clickable: true }}
            spaceBetween={0}
            slidesPerView={1}
          >
            {gallery.map((image: { url: string }) => {
              return (
                <SwiperSlide key={image?.url}>
                  <img src={image?.url}></img>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className='assets'>
          {assets.map((asset: Asset) => {
            return <DownloadBox key={asset?.name} name={asset?.name} file={asset?.link} />;
          })}
        </div>
      </div>
      <div className='game-about'>
        <div className='title'>
          <h2>About the Game</h2>
        </div>
        <div className='description'>
          <p>{about}</p>
        </div>
        <div className='video'>
          <video preload='auto' loop playsInline autoPlay muted>
            <source src={video} type='video/mp4' />
          </video>
        </div>
      </div>
    </div>
  );
}
