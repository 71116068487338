import { useEffect, useState } from 'react';
import './Header1.scss';
import { useLocation } from 'react-router-dom';

import '../../../src/App.css';
import Logo1 from '../../images/redesign/logo-1.svg';
import Logo2 from '../../images/redesign/logo-2.svg';

interface IHeader1 {
  setLoginSteps: React.Dispatch<React.SetStateAction<number>>;
  setSwapSteps: React.Dispatch<React.SetStateAction<number>>;
  setSwapStepsContract: React.Dispatch<React.SetStateAction<number>>;
  swapSteps: number;
  swapStepsContract: number;
  setShowModalDemo: React.Dispatch<React.SetStateAction<boolean>>;
}

function Header1({
  setShowModalDemo
}: IHeader1) {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const location = useLocation();

  function getCurrentDimension() {
    return window.innerWidth;
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  if (location?.pathname === '/game-start') {
    return null;
  }

  return (
    <nav className='nav'>
      <div className='nav__wrapper'>
        <div className='nav__wrapper__bar'>
          <div className='nav__wrapper__bar__inner'>
            <a href='/#top' className='nav__logo'>
              <img src={Logo1} alt='' />
              <img src={Logo2} alt='' />
            </a>

            <div className='nav__links'>
              <a href='/#products' className='nav__links--item'>
                Products
              </a>
              <a href='/#tech' className='nav__links--item'>
                Technology
              </a>
              <a href='https://medium.com/@tokacity' target='_blank' rel='noreferrer' className='nav__links--item'>
                News
              </a>
              <a href='/#contacts' className='nav__links--item'>
                Contacts
              </a>
              <a href='/client-hub' className='nav__links--item'>
                Client Hub
              </a>
              <a
                onClick={() => setShowModalDemo(true)}
                data-modal='modal-demo'
                className='nav__links--button modal-open'
              >
                <span>Book a demo</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header1;
