import { useNavigate } from 'react-router-dom';
import Logo from '../../../images/client-hub/logo-full.svg';
import LogoMobile from '../../../images/client-hub/logo-mobile.svg';
import { useState } from 'react';

interface ILogin {
  setAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Header({ setAuthorized }: ILogin) {
  const navigate = useNavigate();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? true : false;
  const [openBurger, setOpenBurger] = useState(false);
  return (
    <header>
      <div className='logo'>
        <a href='/'>
          <img src={isMobile ? LogoMobile : Logo} alt='' />
        </a>
      </div>
      <div className='menu'>
        <a href='/client-hub/games'>Games</a>
        <a href='/client-hub/assets'>Assets</a>
        <a href='/client-hub/documents'>Documents</a>
      </div>
      <div className='wrap'>
        <div className={`burger-menu ${openBurger ? 'open' : ''}`} onClick={() => setOpenBurger(!openBurger)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className='search'>
          <button
            className='logout'
            onClick={() => {
              setAuthorized(false);
              sessionStorage.removeItem('access_token');
              navigate('/');
            }}
          ></button>
        </div>
      </div>
      <div className={`burger-content ${openBurger ? 'open' : ''}`}>
        <ul className='burger-navigation'>
          <li>
            <a href='/client-hub/games'>Games</a>
          </li>
          <li>
            <a href='/client-hub/assets'>Assets</a>
          </li>
          <li>
            <a href='/client-hub/documents'>Documents</a>
          </li>
          <li>
            <button
              className='logout'
              onClick={() => {
                setAuthorized(false);
                sessionStorage.removeItem('access_token');
                navigate('/');
              }}
            ></button>
          </li>
        </ul>
      </div>
    </header>
  );
}
