import Girl from '../../../../images/client-hub/girl_banner.png';
import Image from '../../../../images/client-hub/games_banner.png';

export default function Banner() {
  return (
    <div className='games-banner'>
      <div className='left'>
        <div className='title'>
          <h3>Explore the Casinoverse</h3>
        </div>
        <div className='description'>
          <p>
            Introducing new aspects to iGaming industry will keep players coming in for more dopamine spikes, resulting
            in higher retention rates. <br /> <br />
            As video gaming experts we are integrating the industry's most effective and time-tested retention
            strategies into the iGaming world.
          </p>
        </div>
      </div>
      <div className='girl'>
        <img src={Girl} alt='' />
      </div>
      <div className='right'>
        <img src={Image} alt='' />
      </div>
    </div>
  );
}
