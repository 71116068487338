import Icon1 from '../../../images/redesign/icon-linked.svg';
import Icon2 from '../../../images/redesign/icon-twitter.svg';
import Icon4 from '../../../images/redesign/icon-insta.svg';
import Icon5 from '../../../images/redesign/icon-tele.svg';
import Icon6 from '../../../images/redesign/icon-yt.svg';

export default function Footer() {
  return (
    <footer className='client-hub-footer'>
      <div className='footer-col first'>
        <div className='footer-row'>
          <h1>contact us</h1>
        </div>
        <div className='footer-row'>
          <h3>
            Leave your message at <a href='mailto:contact@tokacity.com'>contact@tokacity.com </a>and we’ll get back to
            you shortly.
          </h3>
        </div>
      </div>
      <div className='footer-col second'>
        <div className='footer-row menu'>
          <a href=''>privacy policy</a>
          <a href=''>cookie policy</a>
          <a href=''>media kit</a>
          <a href=''>news</a>
        </div>
        <div className='footer-row social'>
          <a href='https://www.linkedin.com/company/tokacity/mycompany/verification/' target='_blank' rel='noreferrer'>
            <img src={Icon1} alt='' />
          </a>
          <a href='https://twitter.com/TOKA_city' target='_blank' rel='noreferrer'>
            <img src={Icon2} alt='' />
          </a>
          <a href='https://www.instagram.com/tokacity_casinoverse/' target='_blank' rel='noreferrer'>
            <img src={Icon4} alt='' />
          </a>
          <a href='https://www.youtube.com/@tokacity.casinoverse' target='_blank' rel='noreferrer'>
            <img src={Icon6} alt='' />
          </a>
        </div>
      </div>
    </footer>
  );
}
