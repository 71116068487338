import './Assets.scss';
import AssetBox from './components/AssetBox';
import Logo from '../../../images/client-hub/logo_assets.png';
import Font from '../../../images/client-hub/font_assets.png';
import Elements from '../../../images/client-hub/elements_assets.png';
import Characters from '../../../images/client-hub/character_assets.png';
import BasicDownload from '../../../images/client-hub/download.svg';
import WhiteDownload from '../../../images/client-hub/download_grey.svg';
import RedDownload from '../../../images/client-hub/download_red.svg';
import BrandReference from '../../../images/client-hub/brand_reference.png';
import ImageAssets from '../../../images/client-hub/image_assets.png';
import Trailer from '../../../video/client-hub/trailer.mp4';
import BannerAssets from '../../../images/client-hub/assets_banner.png';
import { useState } from 'react';

export default function Assets() {
  const [onHoverGuidelines, setOnHoverGuidelines] = useState(false);

  const handlePreviewClick = () => {
    window.open('/TokaCity_MediaKit.pdf', '_blank'); // Opens the PDF in a new tab
  };
  return (
    <div className='client-hub-assets'>
      <div className='assets-header'>
        <div className='title'>
          <h1>Tokacity brand assets</h1>
        </div>
        <div className='download-search'>
          <div className='download'></div>
          <div className='search'></div>
        </div>
      </div>
      <div className='assets-1'>
        <div className='brand-guidelines'>
          <div className='title'>
            <h2>Brand Guidelines</h2>
          </div>
          <div className='description'>
            <p>
              Our Brand Guidelines are your go-to resource for maintaining consistency and integrity across all
              communications. Here, you'll find everything you need to accurately represent our brand, including logo
              usage, color schemes, typography, and co-branding practices. Please ensure that all visuals align with
              these standards to preserve the brand's identity and create impactful, cohesive designs.
              <br /> <br />
              This description provides a clear overview of what users can expect from the Brand Guidelines section,
              emphasizing the importance of consistency and proper usage.
            </p>
          </div>
          <div className='buttons'>
            <div className='red-button'>
              <button onClick={handlePreviewClick}>Preview</button>
            </div>
            <a
              className='download'
              onMouseOver={() => setOnHoverGuidelines(true)}
              onMouseOut={() => setOnHoverGuidelines(false)}
              href='/TokaCity_MediaKit.pdf'
              download
            >
              <span>download</span>
              <img src={onHoverGuidelines ? RedDownload : WhiteDownload} alt='' />
            </a>
          </div>
        </div>
        <a className='brand-reference' href='/TokaCity_MediaKit.pdf' download>
          <img src={BrandReference} alt='' />
          <div className='on-hover'>
            <p>download file</p>
            <img src={BasicDownload} alt='' />
          </div>
        </a>
      </div>
      <div className='assets-2'>
        <AssetBox
          name={'Logo'}
          desc={'Our official logos in various formats'}
          image={Logo}
          file='https://backendapist.blob.core.windows.net/images/TokaCity_Logo.zip'
        />
        <AssetBox
          name={'Font'}
          desc={'Approved brand fonts for consistent use'}
          image={Font}
          file='https://backendapist.blob.core.windows.net/images/TokaCity_Fonts.zip'
        />
        <AssetBox
          name={'Game elements'}
          desc={'Key brand assets like casino elements for cohesive designs'}
          image={Elements}
          file='https://backendapist.blob.core.windows.net/images/TokaCity_Elements.zip'
        />
        <AssetBox
          name={'Characters'}
          desc={'3D characters for use in marketing and communications'}
          image={Characters}
          file='https://backendapist.blob.core.windows.net/images/TokaCity_Characters.zip'
        />
      </div>
      <div className='assets-3'>
        <div className='assets'>
          <div className='image'>
            <img src={ImageAssets} alt='' />
          </div>
          <div className='text'>
            <div className='title'>
              <h2>Images (Game Screenshots)</h2>
            </div>
            <div className='description'>
              <p>
                Explore our library of high-quality game screenshots, available for use in promotional materials,
                websites, and social media.
                <br /> <br />
                These images showcase key moments and visuals from our games, helping you create compelling and visually
                engaging content. Be sure to follow our guidelines on image usage to maintain the brand's visual
                standards.
              </p>
            </div>
            <div className='white-button'>
              <a href='https://backendapist.blob.core.windows.net/images/TokaCity_Screenshots.zip' download>
                Download
              </a>
            </div>
          </div>
        </div>
        <div className='assets'>
          <div className='text'>
            <div className='title'>
              <h2>Videos</h2>
            </div>
            <div className='description'>
              <p>
                Find a selection of brand-approved videos, including trailers, promotional clips, and other multimedia
                content.
                <br /> <br />
                These videos are optimized for various platforms and are ideal for marketing, presentations, and online
                distribution. Follow the provided guidelines to ensure correct usage and to maintain the integrity of
                our brand messaging.
              </p>
            </div>
            <div className='white-button'>
              <a href='https://backendapist.blob.core.windows.net/images/TokaCIty_Videos.zip' download>
                Download
              </a>
            </div>
          </div>
          <div className='video'>
            <video preload='auto' loop playsInline autoPlay muted>
              <source src={Trailer} type='video/mp4' />
            </video>
          </div>
        </div>
      </div>
      <div className='assets-4'>
        <div className='banners-social-media'>
          <div className='text'>
            <div className='title'>
              <h2>Banners and social media</h2>
            </div>
            <div className='description'>
              <p>
                Download ready-to-use banners that highlight our brand's key messages and visuals. Perfect for web,
                social media, and email campaigns
                <br /> <br />
                Browse our selection of brand-specific patterns and textures, perfect for backgrounds, overlays, and
                other design elements. These assets help reinforce our brand’s visual consistency.
              </p>
            </div>
            <div className='image mobile'>
              <img src={BannerAssets} alt='' />
            </div>
            <div className='white-button'>
              <a href='https://backendapist.blob.core.windows.net/images/Ascend_Arena_banners_and_social_media.zip'>
                Download
              </a>
            </div>
          </div>
          <div className='image'>
            <img src={BannerAssets} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}
