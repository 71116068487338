import { Routes, Route, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

import Footer from './components/Footer/Footer';
import { ConnectionContext } from './context/Connection';
import AuthGuard from './components/ProtectedRoutes/authGuard';
import PixelStreaming from './components/PixelStreaming/PixelStreaming';
import Header1 from './components/Header/Header1';
import Login from './components/Login/Login';
import Loading from './components/Loading/Loading';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { AuthControllerContext } from './context/AuthController';

import * as fetcher from './helpers/fetcher';

import { SERVER_URL } from './constants';

import './App.scss';

import { heartbeat } from './services/metricsService';
import Redesign from './components/Redesign/Redesign';
import ClientHub from './components/ClientHub/ClientHub';
import Games from './components/ClientHub/Games/Games';
import Assets from './components/ClientHub/Assets/Assets';
import Documents from './components/ClientHub/Documents/Documents';

function App() {
  const { user, fetchingUser, setUser, getUser, disconnectUser, loading } = useContext(AuthControllerContext);
  const { connecting, wallet } = useContext(ConnectionContext);

  const [loginSteps, setLoginSteps] = useState<number>(0);
  const [swapSteps, setSwapSteps] = useState<number>(0);
  const [swapStepsContract, setSwapStepsContract] = useState<number>(0);
  let [searchParams, setSearchParams] = useSearchParams();

  const [showModalDemo, setShowModalDemo] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem('firstLoad')) {
      getUser().then((user: any) => {
        user;
      });
      sessionStorage.setItem('firstLoad', '1');
    }

    let socket: any = undefined;
    if (SERVER_URL) {
      socket = io(SERVER_URL);

      socket.on('update-money', () => {
        getUser();
      });

      socket.on('add-casino-tokens', () => {
        getUser();
      });
      socket.on('withdraw-money', () => {
        getUser();
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const demoUserHash = async () => {
      let hash = searchParams.get('demoUser');
      if (hash?.length) {
        const response = await fetcher.post('/auth/login-demo-users', {}, { hash: hash });
        const token = response.accessToken;
        localStorage.setItem('bt', token);

        if (token) {
          getUser(token);
        }
      }
    };

    demoUserHash().catch((error) => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    checkLoginValidation();
  }, [wallet]);

  async function checkLoginValidation() {
    if (searchParams.has('login') && searchParams.get('login') == '1' && wallet) {
      const status = await fetcher.ValidateToken(localStorage.getItem('bt'), 'wallet!.accounts[0]!.address');

      if (status !== 'verified') {
        disconnectUser(true);
        setLoginSteps(1);
      }
      setSearchParams('');
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (user) {
        heartbeat(localStorage.getItem('bt'));
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [user]);

  console.log(window.location)

  return (
    <div className='App position-relative'>
      {!String(window.location.pathname).includes('client-hub') && (
        <Header1
          setLoginSteps={setLoginSteps}
          setSwapSteps={setSwapSteps}
          swapStepsContract={swapStepsContract}
          swapSteps={swapSteps}
          setSwapStepsContract={setSwapStepsContract}
          setShowModalDemo={setShowModalDemo}
        />
      )}
      {fetchingUser || connecting || loading ? (
        <Loading></Loading>
      ) : (
        <>
          <Login loginSteps={loginSteps} setLoginSteps={setLoginSteps} />
          <Routes>
            <Route
              path=''
              element={<Redesign showModalDemo={showModalDemo} setShowModalDemo={setShowModalDemo}></Redesign>}
            />
            <Route path='/client-hub/*' element={<ClientHub />} />
            <Route element={<AuthGuard user={user} navigateAddress={'/'} setLoginSteps={setLoginSteps} />}>
              <Route path='/game-start' element={<PixelStreaming />} />
            </Route>
            <Route path='*' element={<ErrorPage />} />
          </Routes>
          {!String(window.location.pathname).includes('client-hub') && <Footer setLoginSteps={setLoginSteps} />}
        </>
      )}
    </div>
  );
}

export default App;
