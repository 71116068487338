import { useState } from 'react';
import './Documents.scss';
import DownloadBox from './components/DownloadBox';
import Filter from './components/Filter';

export default function Documents() {
  const [activeFilters, setActiveFilters] = useState<string[]>(['All']);
  console.log(activeFilters);
  return (
    <div className='client-hub-documents'>
      <div className='title'>
        <h1>Documents</h1>
      </div>
      <div className='filters'>
        <Filter name='All' setActiveFilters={setActiveFilters} />
        <Filter name='Game Sheets' setActiveFilters={setActiveFilters} />
        <Filter name='Certificates' setActiveFilters={setActiveFilters} />
        <Filter name='Integration' setActiveFilters={setActiveFilters} />
      </div>
      <div className='documents'>
        {/* <DownloadBox name='download all' activeFilters={activeFilters} type='All' file='' /> */}
        <DownloadBox
          name='Ascend arena game sheet'
          activeFilters={activeFilters}
          type='Game Sheets'
          file='/GameSheet_Tokacity_ASCEND_ARENA.pdf'
        />
        <DownloadBox
          name='ftn arena game sheet'
          activeFilters={activeFilters}
          type='Game Sheets'
          file='https://backendapist.blob.core.windows.net/images/Tokacity%20FTN%20Gamesheet_NEW_compressed.pdf'
        />
      </div>
    </div>
  );
}
