import { useState } from 'react';
import Download from '../../../../images/client-hub/download_black.svg';
import DownloadRed from '../../../../images/client-hub/download_red.svg';

interface IDownload {
  name: string;
  file: string;
}
export default function DownloadBox({ name, file }: IDownload) {
  const [onHover, setOnHover] = useState(false);
  return (
    <a
      href={file}
      download
      className='download-box'
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className='title'>
        <p>{name}</p>
      </div>
      <div className='icon'>
        <img src={onHover ? DownloadRed : Download} alt='' />
      </div>
    </a>
  );
}
