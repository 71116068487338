interface IAsset {
  image: string;
  name: string;
  desc: string;
  file: string;
}

export default function AssetBox({ image, name, desc, file }: IAsset) {
  return (
    <div className='asset-box'>
      <div className='image'>
        <img src={image} alt='' />
      </div>
      <div className='name'>
        <h3>{name}</h3>
      </div>
      <div className='description'>
        <p>{desc}</p>
      </div>
      <div className='white-button'>
        <a href={file} download={file}>
          Download
        </a>
      </div>
    </div>
  );
}
