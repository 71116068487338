import { useEffect, useState } from 'react';

interface IFilter {
  name: string;
  setActiveFilters: React.Dispatch<React.SetStateAction<string[]>>;
}
export default function Filter({ name, setActiveFilters }: IFilter) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      if (name === 'All') {
        // If "All" is clicked and activated, reset activeFilters to only "All"
        setActiveFilters(['All']);
      } else {
        // If any other filter is activated, remove "All" and add the new filter
        setActiveFilters((prev) => [...prev.filter((filterName) => filterName !== 'All'), name]);
      }
    } else {
      // If deactivating, remove the filter
      setActiveFilters((prev) => {
        const newFilters = prev.filter((filterName) => filterName !== name);
        // If no filters remain active, default back to "All"
        return newFilters.length === 0 ? ['All'] : newFilters;
      });
    }
  }, [isActive]);
  return (
    <div className={`filter ${isActive ? 'active' : ''}`}>
      <p onClick={() => setIsActive(!isActive)}>{name}</p>
    </div>
  );
}
