import { useState } from 'react';
import './ClientHub.scss';
import ClientArea from './components/ClientArea';
import Login from './components/Login';

export default function ClientHub() {
  const [authorized, setAuthorized] = useState(false);
  return (
    <div className='client-hub'>
      {authorized ? <ClientArea setAuthorized={setAuthorized} /> : <Login setAuthorized={setAuthorized} />}
    </div>
  );
}
