import Download from '../../../../images/client-hub/download.svg';
import { useNavigate } from 'react-router-dom';

interface IGameCard {
  gameImage: string;
  gameName: string;
  gameType: string;
  url: string;
}

export default function GameCard({ gameImage, gameName, gameType, url }: IGameCard) {
  const navigate = useNavigate();
  return (
    <div className='game-card'>
      <div className='image'>
        <img src={gameImage} alt='' />
        <div className='hover-effect' onClick={() => navigate(`/client-hub/games/${url}`)}>
          <p>download package</p>
          <img src={Download} alt='' />
        </div>
      </div>
      <div className='info'>
        <div className='wrap'>
          <h3 onClick={() => navigate(`/client-hub/games/${url}`)}>{gameName}</h3>
          <h5>{gameType}</h5>
        </div>
        <div className='button'>
          <button onClick={() => navigate(`/client-hub/games/${url}`)}>Explore the Game</button>
        </div>
      </div>
    </div>
  );
}
